










body {
	margin: 0;
	padding: 0;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.center {
	text-align: center;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	margin: auto;
	width: max-content;
	height: max-content;
}

.logo {
	height: 140px;
}

.divider {
	margin-top: 20px;
	height: 1px;
	width: 300px;
	border-radius: 2px;
	background-color: lightgray;
}

.text {
	font-size: 22px;
	font-weight: 500;
}